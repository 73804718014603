import { useEffect, useState } from 'react';
import dumbbells from '../../images/dumbbells.jpeg'
import '../home/Home.css'
import './Dumbbell.css'

const Dumbbell = () => {
    const [date, setDate] = useState('')
    useEffect(() => {
        const d = new Date();
        d.setMonth(d.getMonth() - 2);
        d.setDate(d.getDate() - 8)
        setDate(d.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) )
    }, [])

    return (
        <div className="dumbbell-container">
            <div className="home-text-container">
                <a
                    href="https://www.amazon.com/gp/product/B001ARYU58/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B001ARYU58&amp;linkId=42a68309fe98941277a1b9b5b6428101"
                    target="_blank"
                    className="outside-link"
                    rel="noopener noreferrer"
                >
                    <img src={dumbbells} className="main-image dumbbell-image" alt="dumbbells" />
                </a>
                <p className="last-updated">Last updated on {date}</p>
                <div className="home-header-container">
                    <div className="home-divider">|</div>
                    <h1 className="home-header">Best Dumbbell Set For Your Home Gym</h1>
                </div>
                <p className="paragraph">
                    The Best Dumbbell Set for Small Spaces is the
                    <a
                        href="https://www.amazon.com/gp/product/B001ARYU58/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B001ARYU58&amp;linkId=42a68309fe98941277a1b9b5b6428101"
                        target="_blank"
                        className="outside-link"
                        rel="noopener noreferrer"
                    >
                        Bowflex SelectTech 552 Adjustable Dumbbells
                    </a>
                    . I expected a lot from these and they're exceeding my expectations.
                    They're nicely designed and well-manufactured.
                    The weight-changing mechanism is clever and not overly-complicated.
                    In my apartment I don't have space for many pairs of dumbbells.
                    The ability to quickly switch between light and heavy weights is
                    allowing me to do workouts that I haven't been able to do before
                    with my one pair of heavy dumbbells. The price feels like a lot for
                    a couple of weights but I'm really kicking myself for not buying these years ago.
                </p>
                <p className="paragraph">The Bowflex SelectTech 552 Adjustable Dumbbells are an innovative and versatile piece of fitness equipment that allows you to easily adjust the weight of your dumbbells with just the turn of a dial. These dumbbells are built with high-quality materials and offer a wide range of weight options, making them an excellent choice for anyone looking to build strength and muscle mass.</p>
                <p className="paragraph">One of the standout features of the Bowflex SelectTech 552 Adjustable Dumbbells is their adjustability. With just the turn of a dial, you can quickly and easily adjust the weight of each dumbbell from 5 to 52.5 pounds, making it possible to perform a wide range of exercises without having to switch between multiple sets of dumbbells.</p>
                <p className="paragraph">The dumbbells also feature a durable construction. Made from high-quality materials, the weights are built to last and can withstand even the most intense workouts. Additionally, the dumbbells include a comfortable grip that provides a secure hold, even when your hands are sweaty.</p>
                <p className="paragraph">Another great feature of the Bowflex SelectTech 552 Adjustable Dumbbells is their compact size. The dumbbells take up very little space, making them an ideal choice for anyone with a small home gym or workout area. The weights come with a sleek and sturdy stand, which makes it easy to store them in between workouts and keep them organized.</p>
                <p className="paragraph">Other features of the Bowflex SelectTech 552 Adjustable Dumbbells include their versatility and ease of use. The weights can be used to perform a wide variety of exercises, including curls, presses, and squats, and their easy-to-use dial system allows you to quickly switch between different weights as needed.</p>
                <p className="paragraph">
                    Overall, the Bowflex SelectTech 552 Adjustable Dumbbells are an excellent choice for anyone looking for a versatile and convenient piece of fitness equipment. With their adjustable weight, durable construction, and compact size, these dumbbells are sure to help you achieve your fitness goals and improve your overall strength and muscle tone.
                    <a
                        href="https://www.amazon.com/gp/product/B001ARYU58/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B001ARYU58&amp;linkId=42a68309fe98941277a1b9b5b6428101"
                        target="_blank"
                        className="outside-link"
                        rel="noopener noreferrer"
                    >
                        $249, Amazon
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Dumbbell;
