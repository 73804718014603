import { useEffect, useState } from 'react';
import bike from '../../images/bike.jpeg'
import '../home/Home.css'
import './Bike.css'

const Bike = () => {
    const [date, setDate] = useState('')
    useEffect(() => {
        const d = new Date();
        d.setMonth(d.getMonth() - 3);
        d.setDate(d.getDate() - 17)
        setDate(d.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) )
    }, [])

    return (
        <div className="bike-container">
            <div className="home-text-container">
                <a
                    href="https://www.amazon.com/gp/product/B002CVU2HG/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B002CVU2HG&amp;linkId=75fc97d06a369ad170478b1c377c7a1d"
                    target="_blank"
                    className="outside-link"
                    rel="noopener noreferrer"
                >
                    <img src={bike} className="main-image bike-image" alt="exercise bike" />
                </a>
                <p className="last-updated">Last updated on {date}</p>
                <div className="home-header-container">
                    <div className="home-divider">|</div>
                    <h1 className="home-header">Best Exercise Bike For Your Apartment</h1>
                </div>
                <p className="paragraph">
                    The Best Exercise Bike for Small Spaces is the
                    <a
                        href="https://www.amazon.com/gp/product/B002CVU2HG/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B002CVU2HG&amp;linkId=75fc97d06a369ad170478b1c377c7a1d"
                        target="_blank"
                        className="outside-link"
                        rel="noopener noreferrer"
                    >
                        Sunny Health &amp; Fitness Pro Indoor Cycling Bike
                    </a>
                    . This product is a little more pricey than some of the other
                    recommended products for your home gym, but for those of
                    you who are serious about burning some calories without leaving the
                    house, this is an absolutely great investment.
                </p>
                <p className="paragraph">The Sunny Health & Fitness Pro Indoor Cycling Bike is a high-quality fitness equipment that is designed to provide you with an intense and effective workout. This bike is built with a heavy-duty steel frame and features a variety of adjustable settings that make it suitable for users of all fitness levels.</p>
                <p className="paragraph">One of the standout features of this indoor cycling bike is its belt drive mechanism. The belt drive ensures a smooth and quiet ride, allowing you to focus on your workout without being disturbed by noisy equipment. Additionally, the belt drive requires minimal maintenance, so you can spend more time working out and less time maintaining your bike.</p>
                <p className="paragraph">Another great feature of the Sunny Health & Fitness Pro Indoor Cycling Bike is its adjustable resistance. With a resistance knob, you can easily increase or decrease the intensity of your workout, making it perfect for beginners and experienced cyclists alike. The bike also includes a convenient push-down brake system, allowing you to quickly stop the bike when necessary.</p>
                <p className="paragraph">The bike's seat and handlebars are both fully adjustable, allowing you to find the perfect riding position for your body. The seat is padded and comfortable, ensuring that you can ride for longer periods of time without experiencing discomfort. The handlebars are also padded and feature multiple grip positions, giving you the ability to switch up your hand placement during your workout.</p>
                <p className="paragraph">Other features of the Sunny Health & Fitness Pro Indoor Cycling Bike include a built-in monitor that displays your time, speed, distance, and calories burned. The monitor is easy to read and allows you to track your progress throughout your workout. Additionally, the bike includes transportation wheels, making it easy to move and store the bike when not in use.</p>
                <p className="paragraph">
                    Overall, the Sunny Health & Fitness Pro Indoor Cycling Bike is an excellent option for anyone looking for a high-quality, effective indoor cycling bike. With its adjustable resistance, comfortable seat and handlebars, and built-in monitor, this bike is perfect for anyone looking to improve their cardiovascular fitness and overall health.
                    <a
                        href="https://www.amazon.com/gp/product/B002CVU2HG/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&amp;tag=apartmentwork-20&amp;camp=1789&amp;creative=9325&amp;linkCode=as2&amp;creativeASIN=B002CVU2HG&amp;linkId=75fc97d06a369ad170478b1c377c7a1d"
                        target="_blank"
                        className="outside-link"
                        rel="noopener noreferrer"
                    >
                        $256, Amazon
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Bike;
